




























































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ServiceOrganizationDto,
  AuditFlowScope
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
@Component({
  name: "RecordList",
  components: {
    PagedTableView,
    AgileAuditTag,
    ExportButton
  },
})
export default class ServiceRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;
  serviceOrganizationList: ServiceOrganizationDto[] = [];
  serviceObjectList: any = [];

  queryForm: any = {
    volunteerOrgCode: undefined,
    surname: undefined,
    title: undefined,
    serviceOrganizationId: undefined,
    serviceObjectIds: undefined,
    typeId: undefined,
    serviceStartTime: undefined,
    serviceEndTime: undefined,
  };

  serviceTypeList: DataDictionaryDto[] = [];

  created() {
    this.fetchDataDictionary();
    this.getServiceObjectList()
  }
  async fetchDataDictionary() {
    //服务类型
    await api.dataDictionary.getDataDictionaryListByKey({
      key: 'ServiceType',
      maxResultCount: 65535,
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.serviceTypeList = res.items!;
    })

    await api.serviceOrganization.getAllServiceOrganizationList().then((res: any) => {
      this.serviceOrganizationList = res!;
    })
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.serviceRecord.getAll(params);
  }

  async getServiceObjectList() {
    await  api.serviceObject.getAll({maxResultCount: 65535}).then((res) => {
      this.serviceObjectList = res.items!;
    })
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ServiceRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: any) {
    this.$router.push({
      name: "serviceRecord-detail",
      params: { id: row.id!.toString() },
    });
  }
}
